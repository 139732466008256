export type Option<T extends string = string> = {
    value: T;
    label: string;
};

export const resolveOptionLabel = (options: Option[], value: string): string => {
    const option = options.find((option) => option.value === value);

    if (!option) {
        throw new Error(`Could not find option for value "${value}"`);
    }

    return option.label;
};

export const carrierOptions = [
    { value: "fedex", label: "FedEx" },
    { value: "ups", label: "UPS" },
    { value: "other", label: "Other" },
] as const satisfies Option[];

export const fedexServiceOptions = [
    { value: "2_day", label: "2Day" },
    { value: "2_day_am", label: "2Day AM" },
    { value: "express_saver", label: "Express Saver" },
    { value: "first_overnight", label: "First Overnight" },
    { value: "ground", label: "Ground" },
    { value: "international_economy", label: "International Economy" },
    { value: "international_first", label: "International First" },
    { value: "international_next_flight", label: "International Next Flight" },
    { value: "international_priority", label: "International Priority" },
    { value: "priority_overnight", label: "Priority Overnight" },
    { value: "standard_overnight", label: "Standard Overnight" },
] as const satisfies Option[];

export const upsServiceOptions = [
    { value: "2nd_day_air", label: "2nd Day Air" },
    { value: "2nd_day_air_am", label: "2nd Day Air A.M." },
    { value: "3_day_select", label: "3 Day Select" },
    { value: "ground", label: "Ground" },
    { value: "international_express_critical", label: "Int'l Express Critical" },
    { value: "international_standard", label: "Int'l Standard" },
    { value: "next_day_air", label: "Next Day Air" },
    { value: "next_day_air_early", label: "Next Day Air Early" },
    { value: "next_day_air_saver", label: "Next Day Air Saver" },
    { value: "worldwide_expedited", label: "Worldwide Expedited" },
    { value: "worldwide_express", label: "Worldwide Express" },
    { value: "worldwide_express_plus", label: "Worldwide Express Plus" },
    { value: "worldwide_saver", label: "Worldwide Saver" },
] as const satisfies Option[];

export const paymentOptions = [
    { value: "prepaid", label: "PrePaid" },
    { value: "collect", label: "Collect" },
    { value: "third_party", label: "Third Party" },
] as const satisfies Option[];

export const shippingInsuranceOptions = [
    { value: "standard", label: "Standard ($500 Shipping Insurance)" },
    { value: "custom", label: "Additional/Custom" },
] as const satisfies Option[];

export const trimOptions = [
    { value: "decorative_cord", label: "Decorative Cord" },
    { value: "decorative_fringe", label: "Decorative Fringe" },
    { value: "decorative_fringe_with_tassels", label: "Decorative fringe with Tassels" },
    { value: "key_tassels", label: "Key Tassels" },
    { value: "tiebacks_under_10_inches", label: "Tiebacks Under 10 Inches" },
    { value: "tiebacks_11_inches_over", label: "Tiebacks 11 Inches or Over" },
] as const satisfies Option[];

export const serviceOptions = [
    { value: "backing", label: "Backing" },
    { value: "flame_retardant", label: "Flame Retardant" },
    { value: "lamination_service", label: "Lamination Service" },
    { value: "other_service", label: "Other Service" },
    { value: "stain_protection", label: "Stain Protection" },
    { value: "lab_service", label: "Lab Service" },
] as const satisfies Option[];

export type ServiceType = (typeof serviceOptions)[number]["value"];

export const backingOptions = [
    { value: "117_barrier", label: "117 Barrier Backing" },
    { value: "acrylic", label: "Acrylic Backing" },
    { value: "knit", label: "Knit Backing" },
    { value: "moisture_barrier", label: "Moisture Barrier Backing" },
    { value: "wall_upholstery", label: "Wall Upholstery Backing" },
] as const satisfies Option[];

export const flammabilityCodeOptions = [
    { value: "cal_tb_117_2013", label: "CAL TB 117-2013" },
    { value: "nfpa_701", label: "NFPA 701" },
    { value: "nfpa_260", label: "NFPA 260" },
    { value: "astm_e_84", label: "ASTM E-84" },
] as const satisfies Option[];

export const otherServiceOptions = [
    { value: "antimicrobial", label: "Antimicrobial" },
    { value: "cut_in_half", label: "Cut in Half – Doublewide" },
    { value: "inspection", label: "Inspection" },
    { value: "preshrinking", label: "Preshrinking" },
    { value: "steaming", label: "Steaming" },
    { value: "wash_out", label: "Wash Out" },
    { value: "uv_inhibitor", label: "Ultraviolet Inhibitor" },
] as const satisfies Option[];

export const stainProtectionOptions = [
    { value: "defend", label: "Defend - All Premium Stain Protection" },
    { value: "standard", label: "Standard Stain Protection" },
    { value: "pfas", label: "PFAS-Free C0 Stain Protection" },
] as const satisfies Option[];

export const fabricFaceOptions = [
    { value: "unknown", label: "Unknown" },
    { value: "inside", label: "Rolled to Inside" },
    { value: "outside", label: "Rolled to Outside" },
] as const satisfies Option[];

export const rushOptions = [
    { value: "rush", label: "Rush" },
    { value: "24_rush", label: "24 Rush" },
    { value: "48_rush", label: "48 Rush" },
] as const satisfies Option[];

export type RushType = (typeof rushOptions)[number]["value"];
