import { useAcceptTermsAndConditionsMutation } from "@/mutations/user-information.ts";
import { useUserInformationQuery } from "@/queries/user-information.ts";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link } from "@mui/material";
import type { ReactNode } from "react";

const TermsAndConditionsHandler = (): ReactNode => {
    const { isAuthenticated, logout } = useAuth0();
    const userInformationQuery = useUserInformationQuery(isAuthenticated);
    const acceptTermsAndConditionsMutation = useAcceptTermsAndConditionsMutation();

    if (!isAuthenticated || userInformationQuery.isPending) {
        return null;
    }

    if (userInformationQuery.isError) {
        return (
            <Dialog open>
                <DialogTitle>There is an issue with your account</DialogTitle>
                <DialogContent dividers>
                    Your account cannot be accessed at this time. Please contact{" "}
                    <Link href="mailto:customerservice@sbifinishing.com">
                        customerservice@sbifinishing.com
                    </Link>{" "}
                    for assistance.
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => logout().catch((e) => console.error("Error Logging out", e))}
                    >
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    if (!userInformationQuery.data.termsAndConditions.acceptanceRequired) {
        return null;
    }

    return (
        <Dialog open>
            <DialogTitle>Accept Terms &amp; Conditions</DialogTitle>
            <DialogContent dividers>
                Please read the{" "}
                <Link href="https://sbifinishing.com/terms-conditions/" target="_blank">
                    Terms &amp; Conditions
                </Link>
                .
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => logout().catch((e) => console.error("Error Logging out", e))}
                >
                    Logout
                </Button>
                <LoadingButton
                    loading={acceptTermsAndConditionsMutation.isPending}
                    disabled={acceptTermsAndConditionsMutation.isPending}
                    onClick={() => {
                        acceptTermsAndConditionsMutation.mutate();
                    }}
                >
                    Accept
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default TermsAndConditionsHandler;
